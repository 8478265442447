import React from 'react'

const Gallery = () => {
    return (
        <>
            <div style={{paddingTop: 100}} className='gallery m-5'>
                <div className='title'>
                    <h1 className='md:text-4xl text-3xl mb-2 text-blue-900 font-myFont text-center py-8 font-bold'> Gallery </h1>
                </div>
                <div className='row my-5'>
                    <div className='image col-lg-3 col-md-4 col-sm-12'>
                        <img className='thumbnail' src='Images/banner1.png' />
                    </div>
                    <div className='image col-lg-3 col-md-4 col-sm-12'>
                        <img className='thumbnail' src='Images/banner2.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-4 col-sm-12'>
                        <img className='thumbnail' src='Images/banner3.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-4 col-sm-12'>
                        <img className='thumbnail' src='Images/banner4.jpg' />
                    </div>
                </div>
                <div className='row my-5'>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/banner5.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/deposit-scheme.png' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/gold.png' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/carLoan.jpg' />
                    </div>
                </div>
                <div className='row my-5'>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/goldloan3.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/goldloan4.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/trail2.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/trail3.jpg' />
                    </div>
                </div>
                <div className='row my-5'>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/homeLoan.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/homeloan2.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/homeloan3.jpg' />
                    </div>
                    <div className='image col-lg-3 col-md-3 col-sm-12'>
                        <img className='thumbnail' src='Images/trail4.jpg' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery